import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Tabs } from '../../components/library'

const tabs1 = [
    {
        label: "Gatsby Link",
        to: '/components',
        disabled: false
    },
    {
        label: "Click Event",
        onClick: (e) => {console.log('CLICKED A TAB')},
        disabled: false
    },
    {
        label: "Basic Tab",
        disabled: false
    },
    {
        label: "Disabled Tab",
        disabled: true
    },
]


const tabs2 = [
    {
        label: "Symptoms",
    },
    {
        label: "Spirometer",
    },
    {
        label: "C-ACT",
    },
    {
        label: "Regular Tab",
    },
    {
        label: "Sample Tab",
    },
    {
        label: "Another Tab",
    },
    {
        label: "Yet Another",
    },
    {
        label: "One More Tab",
    },
    {
        label: "Tabs Tabs Tabs",
    },
    {
        label: "The Last Tab",
    },
]


function actions() {
    return (
        <LibraryWrapper>
            <Tabs id="basic-tabs" title="Reports" tabs={tabs1} />
            <hr />
            <Tabs id="scrollable-tabs" title="Reports" variant="scrollable" tabs={tabs2} initialTab={3} />
        </LibraryWrapper>
    )
}
export default actions
